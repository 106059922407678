import { GlobalService } from './../../global/app.global.service';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityActionOptions } from '@ngrx/data';
import { Observable, of, combineLatest } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';
import { Update } from '@ngrx/entity';
import * as calculator_data from '../../../assets/data/carbon_calculator.json';


@Injectable()
export class CalculatorDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private authService: AuthCollectionService ) {
    super('Calculator', http, httpUrlGenerator);
  }

  getAll(): Observable<any[]> { // load for now the current stored report in localStorage
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {defaultUser} = currentUser || {}; 
        return (!currentUser && !defaultUser) ? of([]) : 
        this.loadReports(defaultUser.enterprise_id, defaultUser.user_id)
      }),      
      catchError(() => of([]))
    );
  }

  getWithQuery(params: string | QueryParams | any): Observable<any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(report: any): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {defaultUser} = currentUser || {}; 
        return (!currentUser && !defaultUser) ? of({}) : 
        this.saveReport(defaultUser.enterprise_id, defaultUser.user_id, report)
      }),      
      catchError(() => (of({})))
    );
    /*return this.saveReport(report)
    .pipe(
      map(res => ({...res, ...report})),
      catchError(err => (of({...report})))
    );*/
  }

  update(report: Update<any>): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {defaultUser} = currentUser || {}; 
        return (!currentUser && !defaultUser) ? of([]) : 
        this.saveReport(defaultUser.enterprise_id, defaultUser.user_id, {...report.changes})
      }),      
      catchError(() => (of({})))
    );
   /* return this.saveReport({...report.changes})
    .pipe(
      map(res => ({...res, ...report.changes})),
      catchError(err => (of({...report.changes})))
    );*/
  }

  delete(key: number | string): Observable<any> {   
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {defaultUser} = currentUser || {};
        let url = this.global.deleteReport;
        url = url.replace('{userId}', defaultUser.user_id);
        url = url.replace('{enterprise_id}', defaultUser.enterprise_id);
        url = url.replace('{report_id}', <string>key);
        return (!currentUser && !defaultUser) ? of(false) : this.http.delete<any>(url)
      })
    );
  }

  loadCalculator(enterprise_id, user_id): Observable<any> {
    let calculator = calculator_data;
    return this.http.get(`${this.global.getQuestions}/${enterprise_id}/${user_id}`)
    .pipe(
     map((item: any)=> {
      let [{dictionary}] = item || [{dictionary:[]}];       
      dictionary = (dictionary ||[]).reduce((acc, item)=> (acc = {...acc, ...item}), {});
      return {...calculator, dictionary};
   }))
  }
  
  loadReports(enterprise_id, user_id): Observable<any> {
    return this.http.get(`${this.global.getReports}/${enterprise_id}/${user_id}`)
    .pipe(
      map((item:any) => {
        let array = item && item.length>0 && Array.isArray(item[0]) ? item[0] : item;
        return (array||[]).map(item=> {
          if(item.report_data) {
            try {
              let data = JSON.parse(item.report_data);
              data.created_date = new Date(data.created_date);
              return data;
            } catch (error) {
              return null;
            }
          }            
          return item;
        }).filter(item=>item!=null);
      }));
  }

  saveReport(enterprise_id, user_id, report):Observable<any> {
    return this.http.post(`${this.global.saveReport}/${enterprise_id}/${user_id}/${(report.report_id || report.reportID)}`, JSON.stringify(report))
    .pipe(
      map((res:any) => {        
        let rep = Array.isArray(res) ? res[0] : res;
        try {
          rep = JSON.parse(rep.report_data);
          rep.created_date = new Date(rep.created_date);
        } catch (error) {
          rep = Array.isArray(res) ? res[0] : res;
        }

        return ({ ...report, ...rep})
      }),
      catchError(() => (of({})))
    );
  }

  getEnterpriseClimateTotals(dates):Observable<any> {    
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};
        let url = this.global.getEnterpriseClimateTotals;
        url = url.replace('{enterprise_id}', user.enterprise_id);
        return (!currentUser && !user) ? of(null) : this.http.post<any>(url, JSON.stringify(dates));
      }),
      catchError(() => of(null))
    );
  }

  calculate(data, is_enterprise = false):Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {isSupplier} = data || {};
        let {user} = currentUser || {};
        let url = this.global.calculate;
        url = url.replace('{userId}', user.user_id);
        url = url.replace('{enterprise_id}', user.enterprise_id);
        url = url.replace('{type}', !is_enterprise ? 'individual': isSupplier?'supplier':'enterprise');
        return (!currentUser && !user) ? of(null) : this.http.post<any>(url, JSON.stringify(data));
      }),
      catchError(() => of(null))
    );
  }

  loadAll(): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        return (!currentUser && !user) ? of([]) : this.loadCalculator(user.enterprise_id, user.user_id)
        .pipe(map((calculator)=>({calculator})))
      }),      
      catchError(() => of([]))
    );
  }

  
}

@Injectable()
export class CalculatorCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private service: CalculatorDataService) {
    super('Calculator', elementsFactory);
  }

  load(): Observable<any[]|any> {
    return combineLatest([
      this.service.loadAll(),
      super.load()
    ]).pipe(map(([{calculator}]) => {
      this.setData({...calculator});
      this.setLoaded(true);
      return calculator;
    }));
  }

  /*saveReport(enterprise_id, user_id, currentReport){
   // localStorage.setItem('currentReport', JSON.stringify(currentReport));
   // this.setData({currentReport});
    return this.service.saveReport(enterprise_id, user_id, currentReport);
  }*/

  calculate(data, is_enterprise = false):Observable<any> {
    return this.service.calculate(data, is_enterprise)
    .pipe(
      take(1),
    //  timeout(30000),
      catchError(() => of(null))
    );
  }

  getEnterpriseClimateTotals(dates?):Observable<any> {
    return this.service.getEnterpriseClimateTotals(dates);
  }

  setData(additional: any): Observable<any> {
    let queryParams: any = { additional };
    return this.getWithQuery(queryParams);
  }
  

}
