import { GoalCollectionService } from './goal.service';
import { Milestone } from '../../models/milestone';
import { GlobalService } from '../../global/app.global.service';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityActionOptions } from '@ngrx/data';

import { Observable, of, throwError } from 'rxjs';
import { map, catchError, switchMap, distinct, take } from 'rxjs/operators';


@Injectable()
export class MilestoneDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private goalService: GoalCollectionService, private authService: AuthCollectionService ) {
    super('Milestone', http, httpUrlGenerator);
  }

  getAll(): Observable<Milestone[]> {
    return this.getAllMilestones();
  }

  getWithQuery(params: string | QueryParams | any): Observable<Milestone[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(milestone: Milestone): Observable<Milestone> {
    return this.http.post<any>(this.global.createMilestonesUrl, milestone)
    .pipe(
      map(item=> ({...milestone, ...(item.data||item) })),
      catchError((error) => throwError(error))
    );
  }

  update(milestone: Update<Milestone>): Observable<any> {
    let url = this.global.updateMilestonesUrl.replace('{milestone_number}', <string> milestone.id);
    return this.http.put<any>(url, {...milestone.changes})
    .pipe(
      map(item=> ({...milestone.changes, ...(item.data||item)})),
      catchError((error) => throwError(error))
    );
  }

  delete(key: number | string): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        let url = this.global.deleteMilestonesUrl;
        url = url.replace('{enterprise_id}', user.enterprise_id);
        url = url.replace('{milestone_number}', <string>key);
        return (!currentUser && !user) ? of(false) : this.http.delete<any>(url)
      }),
      catchError((error) => throwError(error))
    );
  }

  getAllMilestones(): Observable<Milestone[]> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
       let {user} = currentUser || {}; 
        return (!currentUser && !user) ? of([]) : 
        this.getMilestones(user.enterprise_id, user.user_id)
        .pipe(
          distinct((item)=>item.milestone_id),
          map(result => {
            return (result || [] )
            .filter(item=> !!item.milestone_id)
            .map(item=> new Milestone(item))
          }))
      }),      
      catchError(() => of([]))
    );
  }
  
  getMilestones(enterpriseId:any, userId:any): Observable<any> {
    let url = `${this.global.getFullGoals}/${enterpriseId}/${userId}`;
    return this.http.get<any>(url);
  }

  getActionMilestones(enterpriseId:any, goalId:any): Observable<any> {
    let url = this.global.getMilestonesUrl;
    url = url.replace('{enterprise_id}', enterpriseId);
    url = url.replace('{goal_id}', goalId);
    return this.http.get<any>(url);
  }

  
}

@Injectable()
export class MilestoneCollectionService  extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: MilestoneDataService) {
    super('Milestone', elementsFactory);
  }

  get milestones$(): Observable<Milestone[]> {
    return this.entities$;
  }

  delete(entity: any, options?: EntityActionOptions): Observable<string | number> {
    let key = `${entity.goal_id}/${entity.milestone_id}`;
    return this.dataService.delete(key);
  }

  getActionMilestones(enterpriseId:any, goalId:any): Observable<any> {
    return this.dataService.getActionMilestones(enterpriseId, goalId);
  }

}
