import { TeamCollectionService } from './team.service';
import { UserCollectionService } from './user.service';
import { GlobalService } from '../../global/app.global.service';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { Observable, of, throwError, combineLatest } from 'rxjs';
import { map, catchError, pluck, tap, mergeMap, switchMap, filter, take } from 'rxjs/operators';
import { Document } from '../../models/document';
import * as _ from 'lodash';


@Injectable()
export class DocumentDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private authService: AuthCollectionService , private userService: UserCollectionService, private teamService: TeamCollectionService) {
    super('Document', http, httpUrlGenerator);
  }

  getAll(): Observable<Document[]> {
    return this.loadDocuments();
  }

  getWithQuery(params: string | QueryParams | any): Observable<Document[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(document: Document): Observable<Document> {
    let {users, teams} = document.share_users || {users:[], teams:[]};
    users = (users||[]).map(item=>item.user_id || item);
    teams = (teams||[]).map(item=>item.team_id || item);
    let share_users = {users, teams};
    
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {defaultUser} = currentUser || {};
        let url = this.global.createDocumentUrl;
        url = url.replace('{userId}', defaultUser.user_id);
        url = url.replace('{enterprise_id}', defaultUser.enterprise_id);
        let item = {...document, share_users, 
          created_user_id : defaultUser.user_id,
          modified_user_id : defaultUser.user_id,
          enterprise_id : defaultUser.enterprise_id
        };
        return (!currentUser && !defaultUser) ? of(null) : 
        this.http.post(url, item)
        .pipe(map((res:any)=>(new Document({...item, ...res, ...(res.data||res) }))
        ))
      }),
      catchError((error) => throwError(error))
    );
  }

  update(document: Update<Document>): Observable<any> {
    let {changes} = document || {};
    let {users, teams} = changes.share_users || {users:[], teams:[]};
    users = (users||[]).map(item=>item.user_id || item);
    teams = (teams||[]).map(item=>item.team_id || item);
    let share_users = {users, teams};
    let restore = (changes.deleted=='y');
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {defaultUser} = currentUser || {};
        let url = this.global.updateDocumentUrl;
        url = url.replace('{userId}', defaultUser.user_id);
        url = url.replace('{enterprise_id}', defaultUser.enterprise_id);
        url = url.replace('{document_id}', <string>document.id);
        let item = {...changes, share_users, modified_user_id : +defaultUser.user_id};
        return (!currentUser && !defaultUser) ? of(false) : 
        this.http.put<any>(url, JSON.stringify(item), { params: restore ? {restore}:undefined})
        .pipe(map((res:any)=>(new Document({...item, ...res, ...(res.data||res) }))))
     }),
      catchError((error) => throwError(error))
    );
  }

  delete(key: number | string): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {defaultUser} = currentUser || {};
        let url = this.global.deleteDocumentUrl;
        url = url.replace('{userId}', defaultUser.user_id);
        url = url.replace('{enterprise_id}', defaultUser.enterprise_id);
        url = url.replace('{document_id}', <string>key);
        return (!currentUser && !defaultUser) ? of(false) : this.http.delete<any>(url)
      }),
      catchError((error) => throwError(error))
    );
  }

  loadDocuments(params?): Observable<Document[]> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {defaultUser} = currentUser || {}; 
        return (!currentUser && !defaultUser) ? of([]) : 
        this.getAllDocuments(defaultUser.enterprise_id, defaultUser.user_id, params)
        .pipe(
          pluck('data'),
          map(result => {
            let all: any[] = (result || []).reduce((acc, curr) => acc.concat(acc, curr), []);
            return all.map(item =>  new Document(item))
          }),
          mergeMap(res=>
           combineLatest([of(res), 
            this.userService.collection$.pipe(filter(item=>!!item.loaded)),
            this.teamService.collection$.pipe(filter(item=>!!item.loaded))
          ])),
          map(([documents, usersCollection, teamsCollection])=>{
            return documents.map(item=>{
              let share_users = null;
              try {
                share_users = JSON.parse(item.share_users);
              } catch (error) {
                share_users = null;
              }
              let {users,teams} = share_users || {users:[], teams:[]};
              users = (users ||[]).map(id=>usersCollection.entities[id])
              teams = (teams ||[]).map(id=>teamsCollection.entities[id])
              item = {...item, size: item.size||item.document_size, share_users: {users,teams}};
              return new Document(item);
            });
          })
        )
      }),      
      catchError(() => of([]))
    );
  }

  getAllDocuments(enterpriseId:any, userId:any, params:any): Observable<any> {
    let url = this.global.getAllDocumentsUrl;
    url = url.replace('{enterprise_id}', enterpriseId);
    url = url.replace('{userId}', userId);
    return this.http.get<any>(url, { params });
  }


  view(document: Document): Observable<any> {
    let url = this.global.viewDocumentUrl;
    url = url.replace('{userId}', document.created_user_id.toString());
    url = url.replace('{enterprise_id}', document.enterprise_id.toString());
    url = url.replace('{document_id}', document.document_id.toString());
    return this.http.get<any>(url)
    .pipe(
      take(1),
      map(result => {
        return _.isArray(result) ? result[0]: result;
      }),
      catchError((error) => throwError(error))
    );
  }

  empty(): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {defaultUser} = currentUser || {};
        let url = this.global.emptyTrashUrl;
        url = url.replace('{userId}', defaultUser.user_id);
        url = url.replace('{enterprise_id}', defaultUser.enterprise_id);
        return (!currentUser && !defaultUser) ? of(false) : this.http.delete<any>(url)
      }),
      catchError((error) => throwError(error))
    );
  }

  uploadFile(url:any, file:any):Observable<any>{
    return this.http.put<any>(url, file);
  }

}

@Injectable()
export class DocumentCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: DocumentDataService) {
    super('Document', elementsFactory);
  }

  setData(additional: any): Observable<any> {
    let queryParams: any = { additional };
    return this.getWithQuery(queryParams);
  }

  get documents$(): Observable<Document[]> {
    return this.entities$;
  }

  get trash$(): Observable<Document[]> {
    return this.collection$.pipe(map((item: any) => item.trash));
  }

  empty(): Observable<any> {
    return this.dataService.empty().pipe(tap(() => super.load()));
  }

  view(document: Document): Observable<any> {
    return this.dataService.view(document);
  }
  uploadFile(url:any, file:any):Observable<any>{
    return  this.dataService.uploadFile(url, file);
  }

}
