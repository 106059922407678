import { Goal } from './../../models/goal';
import { GlobalService } from '../../global/app.global.service';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { Observable, combineLatest, of, throwError } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';
import { Milestone } from '../../models/milestone';


@Injectable()
export class GoalDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private authService: AuthCollectionService ) {
    super('Goal', http, httpUrlGenerator);
  }

  getAll(): Observable<Goal[]> {
    return this.getGoals();
  }

  getWithQuery(params: string | QueryParams | any): Observable<Goal[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(goal: Goal): Observable<Goal> {
    return this.http.post<any>(this.global.createGoalUrl, goal)
    .pipe(
      map(item=> ({...goal, ...(item.data||item) })),
      catchError((error) => throwError(error))
    );
  }

  update(goal: Update<Goal>): Observable<any> {
    let url = this.global.updateGoalUrl.replace('{goal_id}', <string> goal.id);
    return this.http.put<any>(url, {...goal.changes})
    .pipe(
      map(item=> ({...goal.changes, ...(item.data||item)})),
      catchError((error) => throwError(error))
    );
  }

  delete(key: number | string): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        let url = this.global.deleteGoalUrl;
        url = url.replace('{enterprise_id}', user.enterprise_id);
        url = url.replace('{goal_id}', <string>key);
        return (!currentUser && !user) ? of(false) : this.http.delete<any>(url)
      }),
      catchError((error) => throwError(error))
    );
  }

  getGoals(): Observable<Goal[]> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {defaultUser} = currentUser || {}; 
        return (!currentUser && !defaultUser) ? of([]) : 
        this.getAllGoals(defaultUser.enterprise_id,defaultUser.user_id)
        .pipe(
          //distinct((item)=>item.goal_id),
          map(result => {
            return (result || [] ).reduce((acc,curr)=>{
              let filtered = acc.filter(item=>item.goal_id!=curr.goal_id);
              let item = acc.find(item=>item.goal_id==curr.goal_id) || curr;
              let milestones:any[] = item.milestones || [];
              if(curr.milestone) {
                milestones.push(new Milestone({...curr, 
                  status: curr.milestone_status, 
                  description: curr.milestone_description,
                  target_date: curr.milestone_target_date,
                  created_date: curr.milestone_created_date,
                  completed_date: curr.milestone_completed_date                
                }));
              }

              let progress = milestones.length >0 ?  ((milestones.filter(item=> item.status=='completed').length / milestones.length) * 100) : 0;
              return [...filtered, new Goal({...item, ...curr, milestones, progress,
                status: curr.goal_status, 
                description: curr.goal_description,
                target_date: curr.goal_target_date,
                created_date: curr.goal_created_date,
                completed_date: curr.goal_completed_date
              })];
            }, []);
          }))
      }),      
      catchError(() => of([]))
    );
  }

  getKPI(): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {defaultUser} = currentUser || {}; 
        let url = `${this.global.getKPI}/${defaultUser.enterprise_id}/${defaultUser.user_id}`;
        return (!currentUser && !defaultUser) ? of([]) : this.http.get<any>(url);
      }),
      map(item=> item && item.length>0 ? JSON.parse(item[0].kpi_data||'')||[] : item.kpi_data || []),      
      catchError(() => of([]))
    );
  }

  setKPI(kpi: any): Observable<Goal> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        let url = `${this.global.setKPI}/${user.enterprise_id}/${user.user_id}`;
        return (!currentUser && !user) ? of([]) : this.http.post<any>(url, kpi);
      })
    );
  }

  getAllGoals(enterpriseId:any, userId:any): Observable<any> {
    let url = `${this.global.getFullGoals}/${enterpriseId}/${userId}`;
    return this.http.get<any>(url);
  }
}

@Injectable()
export class GoalCollectionService  extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: GoalDataService) {
    super('Goal', elementsFactory);
  }

  load(): Observable<any> {
    return super.load()
    .pipe(
      switchMap(res=>combineLatest([of(res),this.getKPI()])),
      map(([goals, kpi]) => (goals)),
      catchError(() => of({})));
  }

  get goals$(): Observable<Goal[]> {
    return this.entities$;
  }

  setData(additional: any): Observable<any> {
    let queryParams: any = { additional };
    return this.getWithQuery(queryParams);
  }

  get kpi$(): Observable<any> {
    return this.collection$.pipe(map((item: any) => item.kpi));
  }

  getKPI(): Observable<any> {
    return this.dataService.getKPI()
    .pipe(map(kpi=>{
      this.setData({kpi});
      this.setLoaded(true);  
      return kpi;
    }),
    catchError(() => of({})));
  }

  setKPI(kpi: any): Observable<any> {
    return this.dataService.setKPI(kpi)
    .pipe(map(res=>{
      this.setData({kpi});
      this.setLoaded(true);  
      return kpi;
    }),
    catchError(() => of({})));
  }

}
