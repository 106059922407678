
import * as _ from 'lodash';
export class Supplier {
    id: string;
    supplierID: string;
    supplierName: string;
    supplierAddress: string;
    supplierContactName: string;
    supplierContactEmail: string;
    supplierContactPhone: string;
    supplierWebsite: string;
    supplierRegistrationNumber: string;
    supplierRevenue: string;
    supplierSpend: string;
    supplierScope1: number;
    supplierScope2: number;
    apportionPercent: number;
    progress:boolean;
    enterpriseID: string;
    createdDate?:Date;
    updatedDate?:Date;

    constructor(data) {
        for (const key in data) {
            if(key.toLowerCase().includes('date'))
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        }
        this.id = data.id || this.supplierID;
    }
}