import { Category } from '../../models/category';
import { EnterpriseService } from '../../services/enterprise.service';
import { GlobalService } from '../../global/app.global.service';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityCacheDispatcher } from '@ngrx/data';

import { Observable, of, from, forkJoin, throwError } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';


@Injectable()
export class CategoryDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private service: EnterpriseService, private authService: AuthCollectionService ) {
    super('Category', http, httpUrlGenerator);
  }

  getAll(): Observable<Category[]> {
    return this.getAllCategories();
  }

  getWithQuery(params: string | QueryParams | any): Observable<Category[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(category: Category): Observable<Category> {
    return this.http.post<any>(this.global.createActionsCategoryUrl, category)
    .pipe(
      map(item=> ({...category, ...(item[0] || item.data||item) })),
      catchError((error) => throwError(error))
    );
  }

  update(category: Update<Category>): Observable<any> {
    let url = this.global.updateActionsCategoryUrl.replace('{categoryId}', <string> category.id);
    return this.http.put<any>(url, {...category.changes})
    .pipe(
      map(item=> ({...category.changes, ...(item.data||item)})),
      catchError((error) => throwError(error))
    );
  }

  delete(key: number | string): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {defaultUser} = currentUser || {};
        let url = this.global.ToDeleteActionsCategoryUrl;
        url = url.replace('{userId}', defaultUser.user_id);
        url = url.replace('{enterpriseId}', defaultUser.enterprise_id);
        url = url.replace('{categoryId}', <string>key);
        return (!currentUser && !defaultUser) ? of(false) : this.http.delete<any>(url)
      }),
      catchError((error) => throwError(error))
    );
  }

  getAllCategories(): Observable<Category[]> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {defaultUser} = currentUser || {}; 
        return (!currentUser && !defaultUser) ? of([]) : 
        this.getCategories(defaultUser.enterprise_id, defaultUser.user_id)
        .pipe(
          map(result => {
            return (result || [] ).map(item=> new Category(item))
          }))
      }),      
      catchError(err => of([]))
    );
  }

  getCategories(enterpriseId:any, userId:any): Observable<any> {
    let url = this.global.getCategoriesUrl;
    url = url.replace('{enterprise_id}', enterpriseId);
    url = url.replace('{user_id}', userId);

    // console.log(url);
    return this.http.get<any>(url);
  }

  createActionsCategorydefault(enterpriseId:any, userId:any,role_id:any): Observable<any> {
    let url = this.global.createActionsCategoryDefaultUrl;
    url = url.replace('{type}', role_id);
    url = url.replace('{enterpriseId}', enterpriseId);
    url = url.replace('{userId}', userId);
    // console.log(url);
    return this.http.post<any>(url, { observe: 'response' });
  }
}

@Injectable()
export class CategoryCollectionService  extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: CategoryDataService) {
    super('Category', elementsFactory);
  }

  get categories$(): Observable<Category[]> {
    return this.entities$;
  }

  createActionsCategorydefault(enterpriseId:any, userId:any,role_id:any): Observable<any> {
    return this.dataService.createActionsCategorydefault(enterpriseId, userId,role_id);
  }

}
