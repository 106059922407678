import { GlobalService } from './../../global/app.global.service';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { Observable, of, throwError } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';
import { sortComparerName } from '../entity/entity-metadata';
import { Supplier } from '../../models/supplier';


@Injectable()
export class SupplierDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private authService: AuthCollectionService ) {
    super('Supplier', http, httpUrlGenerator);
  }

  getAll(): Observable<Supplier[]> {
    return this.loadSuppliers();
  }

  getWithQuery(params: string | QueryParams | any): Observable<Supplier[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(supplier: Supplier): Observable<Supplier> {
    return  this.authService.currentUser$
      .pipe(
        take(1),
        switchMap( (currentUser) => {
          let {user} = currentUser || {}; 
          let url = this.global.createSupplierUrl;
          url = url.replace('{enterprise_id}', user.enterprise_id);
          return (!currentUser && !user) ? of(false) : this.http.post<any>(url, supplier)
        }),
        map(item=> new Supplier({...supplier, ...(item[0]||item)})),
        catchError((error) => throwError(error))
      );
  }

  update(supplier: Update<Supplier>): Observable<any> {
    return  this.authService.currentUser$
      .pipe(
        take(1),
        switchMap( (currentUser) => {
          let {user} = currentUser || {}; 
          let url = this.global.updateSupplierUrl;
          url = url.replace('{enterprise_id}', user.enterprise_id);
          url = url.replace('{supplierId}', <string>supplier.id);
          return (!currentUser && !user) ? of(false) : this.http.put<any>(url, supplier.changes)
        }),
        map(item=> new Supplier({...supplier.changes, ...(item[0]||item)})),
        catchError((error) => throwError(error))
      );
  }

  delete(key: number | string): Observable<any> {
    return  this.authService.currentUser$
      .pipe(
        take(1),
        switchMap( (currentUser) => {
          let {user} = currentUser || {}; 
          let url = this.global.deleteSupplierUrl;
          url = url.replace('{enterprise_id}', user.enterprise_id);
          url = url.replace('{supplierId}', <string>key);
          return (!currentUser && !user) ? of(false) : this.http.delete<any>(url)
        }),
        catchError((error) => throwError(error))
      );
  }

  loadSuppliers(): Observable<Supplier[]> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        return (!currentUser && !user) ? of([]) : 
        this.getSuppliers(user.enterprise_id)
        .pipe(
          map(result => {
            return (result || [] ).map(item=>new Supplier(item));
          }))
      }),      
      catchError(() => of([]))
    );
  }

  getSuppliers(enterprise_id:any): Observable<Supplier[]> {
    let url = this.global.getSuppliersUrl;
    url = url.replace('{enterprise_id}', enterprise_id);

    return this.http.get<Supplier[]>(url);
  }
}

@Injectable()
export class SupplierCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: SupplierDataService) {
    super('Supplier', elementsFactory);
  }

  get suppliers$(): Observable<Supplier[]> {
    return this.entities$;
  }
}
