import * as _ from 'lodash';
export class Enterprise {
    //actionsmanagement_notification: string;
    address_line1: string;
    address_line2: string;
    category_id: string;
    category_name: string;
    city_id: string;
    city_name: string;
    //compass: boolean;
    contact_email: string;
    contact_name: string;
    contact_phone: string;
    contact_title: string;
    country_id: string;
    country_name: string;
    created_date: Date;
    email: string;
    enterprise_id: string;
    is_active: string;
    logo: string;
    modified_date: Date;
    name: string;
    phone_code: string;
    pincode: string;
    row_number: string;
    state_id: string;
    state_name: string;
    total_count: number;
    website: string;
    preferences:any = {features:{goals:true, actions:true, calendar:true, dashboard:true, calculator:true, eco_print:true, white_label:false}};
   
    constructor(data) {
        for (const key in data) {
            if (key.toLowerCase().includes('date'))
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else if(key=='preferences'){
                this[key] = ((!data[key] || data[key]=='[object Object]') ? this.preferences : (_.isString(data[key]) ? JSON.parse(data[key]) : data[key])) || this.preferences;
            }
            else
                this[key] = data[key];
        }        
    }
}

export interface EnterpriseCategory {
    category_id: number;
    name: string;
    description: string;
}