import { GlobalService } from 'src/app/global/app.global.service';
import { AppDataServices } from './../store/entity/entity-services';
import { Injectable,  } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, timeout, retry } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor{
    retryCount = 1;
    constructor(private appDataServices: AppDataServices, private route: Router, private globals: GlobalService){
        let err = localStorage.getItem('errorMsg');
        if(err) {            
            this.globals.showErrorMessage(err);
            localStorage.removeItem('errorMsg');
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        return next.handle(request).pipe(
            timeout(30000),
            retry(this.retryCount),
            catchError(err => {            
                let hideError = request.headers.get('X-hideError');
                let serverRunning = (err.error && err.error.status == 'Running');                
                let defaultMsg = `We've experienced a critical error! <br/> Support is aware, please try again later`;                    
                    
                if(err.status === 502  || err.status === 401 || err.status == 403 || (err.name==="TimeoutError" && !this.globals.currentUser)){
                    if(this.globals.currentUser || !this.route.url.includes('/login')){
                        if(!serverRunning && !hideError){
                            let errorMsg = err.name!="TimeoutError" ? (err.error||{}).msg || defaultMsg : defaultMsg;                            
                            localStorage.setItem('errorMsg', errorMsg); // to be displayed after reload
                        }
                        // auto logout if 401 response returned from api
                        this.appDataServices.authService.logout(); 
                    }
                    else if(!serverRunning && !hideError){
                        this.globals.showErrorMessage(err.name!="TimeoutError" ? (err.error||{}).msg || defaultMsg : defaultMsg);
                    }
                }
                else if(!serverRunning && !hideError){
                    this.globals.showErrorMessage(err.name!="TimeoutError" ? (err.error||{}).msg || defaultMsg : defaultMsg);
                }
                
            
            return throwError(err);
        }))
    }
}