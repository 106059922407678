import { Constants } from './../global/app.global.constants';

import * as _ from 'lodash';
export class User {
    userId: number;
    userName: string;
    firstName: string;
    lastName: string;
    name?: string;
    token?: string;
    authData?: string;
    constructor(data) {
        for (const key in data) {
            if(key.toLowerCase().includes('date'))
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        }
        this.name = [data.firstName, data.lastName].join(' ').trim();
    }
}

export class AuthUser {
    token: string;
    authData: string;
    user: UserItem;
    defaultUser: UserItem;
    userRoles: UserItem[];
    roles?:number[];
    userIndex?:number;
    constructor(data) {
        for (const key in data) {
            if(key.toLowerCase().includes('date'))
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        }
        let {userRoles, user} = data;
        this.userRoles = (userRoles || []).map(user=>  new UserItem({...user, userRoles}))
        if(user)
            this.user = new UserItem({...user, userRoles});
        
        
        this.defaultUser = this.userRoles.find(role=>+role.role_id == Constants.roles.INDIVIDUAL) || 
                           this.userRoles.find(role=>+role.role_id == Constants.roles.TEAM) || user;
        this.defaultUser = new UserItem({...this.defaultUser, userRoles});

        this.roles= [...this.defaultUser.roles].map(id=>+id);

        this.userRoles = (this.roles||[]).map(role_id => {
            return (this.userRoles||[]).find(item => +item.role_id == +role_id) || new UserItem({...this.defaultUser, role_id: role_id.toString()});
        });
    }
}

export class UserItem {
    designation: string;
    email: string;
    employee_id: string;
    enterprise_id: string;
    firstname: string;
    is_firsttime: string;
    is_primary: string;
    lastname: string;
    master_id: string;
    phone_number: string;
    profile_photo: string;
    role_id: string;
    theme_id: string;
    user_id: string;
    user_name: string;
    created_date: Date;
    modified_date: Date;
    last_login: Date;
    name: string;
    preferences: any;
    maxSize: number = 100 * 1024 * 1024;
    userRoles?: UserItem[];
    roles?:string[];
    

    constructor(data) {
        if(!data) return;
        for (const key in data) {
            if(key.toLowerCase().includes('date') || key.toLowerCase()==='last_login')
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]);
            else if(key=='preferences'){
                if(_.isString(data[key]) && data[key]=='[object Object]')
                    data[key]=null;
                this[key] = ((data[key] && _.isString(data[key])) ? JSON.parse(data[key]) : data[key]) || data[key] || {};
            } 
            else if(key=='roles'){
                this[key]= ((data[key] &&_.isString(data[key])) ? data[key].split(',') : data[key]) || data[key] || [];
            }
            else
                this[key] = data[key];
        }
        let roles = (this.userRoles||[]).map(item=>item.role_id);
        roles = [...(this.roles||[]),...roles, this.role_id];
        this.roles =_.uniq(roles);        
        this.name = [data.firstname||'', data.lastname||''].join(' ').trim();
        this.user_name = this.user_name || this.email; // if no user_name set it with email
    }
}