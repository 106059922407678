import { Enterprise, EnterpriseCategory } from '../../models/enterprise';
import { EnterpriseService } from '../../services/enterprise.service';
import { GlobalService } from '../../global/app.global.service';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { Constants } from './../../global/app.global.constants';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { Observable, of, throwError, combineLatest } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';


@Injectable()
export class EnterpriseDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global: GlobalService, private service: EnterpriseService, private authService: AuthCollectionService) {
    super('Enterprise', http, httpUrlGenerator);
  }

  getAll(): Observable<Enterprise[]> {
    return this.getAllEnterprises();
  }

  getWithQuery(params: string | QueryParams | any): Observable<Enterprise[] | any[] | any> {
    const { data } = params;
    return of(data || []);
  }

  add(enterprise: Enterprise): Observable<any> {
    return this.http.post<any>(this.global.createEnterpriseUrl, enterprise)
      .pipe(
        map(item=> (new Enterprise({...enterprise, ...(item.data||item)}))),
        catchError(error => throwError(error))
      );
  }

  update(enterprise: Update<Enterprise>): Observable<any> {
    let url = this.global.updateEnterpriseUrl.replace('{enterprise_id}', <string>enterprise.id);
    return this.http.put<any>(url, { ...enterprise.changes })
      .pipe(
        map(item=> (new Enterprise({...enterprise.changes, ...(item.data||item)}))),
        catchError(error => throwError(error))
      );
  }

  delete(key: number | string): Observable<any> {
    let url = this.global.deleteEnterpriseUrl.replace('{enterprise_id}', <string>key);
    return this.http.delete<any>(url)
      .pipe(
        catchError(({ error }) => throwError(error))
      );
  }

  getCurrentEnterprise(): Observable<any>  {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};
        let url = this.global.togetEnterpriseInfoUrl + "/" + (user||{}).enterprise_id;
        return (!currentUser || !user || !(user||{}).enterprise_id || (user||{}).enterprise_id=='1') ? of(null) :  this.http.get(url);
      }),
      map(item => item ? new Enterprise(item): null),
      catchError(() => of(null))
    );
      
  }

  getAllEnterprises(): Observable<Enterprise[] | any[] | any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};
        let isNotAdmin = !currentUser || !user || (+user.role_id !== Constants.roles.ADMINISTRATOR);
        return isNotAdmin ? of([]) :  this.http.get<any>(this.global.getAllEnterprisesUrl);
      }),
      map(result => (result || []).map(item => item ? new Enterprise(item): {})),
      catchError(() => of([])),          
    );
  }

  getEnterpriseCategories(): Observable<EnterpriseCategory[]> {
    return this.http.get<EnterpriseCategory[]>(this.global.getAllEnterpriseCategoriesUrl);
  }

  createEnterpriseUser(body:any): Observable<any> {
    return this.http.post<any>(this.global.createEnterpriseUserUrl, body);
  }

  mapEnterpriseUser(enterpriseId: any, userId: any): Observable<any> {
    let inputUrl = this.global.mapEnterpriseUserIdUrl;
    inputUrl = inputUrl.replace('{enterprise_id}', enterpriseId);
    inputUrl = inputUrl.replace('{user_id}', userId);
    return this.http.post<any>(inputUrl, {});
  }

  mapEnterpriseFeatures(enterpriseId: any, featureList: any): Observable<any> {
    let url = this.global.mapEnterpriseFeaturesUrl;
    url = url.replace('{enterprise_id}', enterpriseId);
    return this.http.post<any>(url, featureList);
  }

}

@Injectable()
export class EnterpriseCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: EnterpriseDataService) {
    super('Enterprise', elementsFactory);
  }

  load(): Observable<any> {
    return super.load()
    .pipe(
      switchMap(res=>combineLatest([of(res),this.loadEnterprise()])),
      map(([enterprises, currentEnterprise]) => ({enterprises, currentEnterprise})),
      catchError(() => of({})));
  }

  loadEnterprise(): Observable<Enterprise> {
    return this.dataService.getCurrentEnterprise()
    .pipe(map(currentEnterprise=>{
      this.setData({currentEnterprise});
      this.setLoaded(true);  
      return currentEnterprise;
    }),
    catchError(() => of({})));
  }

  getEnterpriseCategories(): Observable<EnterpriseCategory[]> {
    return this.dataService.getEnterpriseCategories();
  }

  createEnterpriseUser(user:any): Observable<any> {
    return this.dataService.createEnterpriseUser(user);
  }

  mapEnterpriseUser(enterpriseId: any, userId: any): Observable<any> {
    return this.dataService.mapEnterpriseUser(enterpriseId, userId);
  }

  mapEnterpriseFeatures(enterpriseId: any, featureList: any): Observable<any> {
    return this.dataService.mapEnterpriseFeatures(enterpriseId, featureList);
  }

  setData(additional: any): Observable<any> {
    let queryParams: any = { additional };
    return this.getWithQuery(queryParams);
  }

  get enterprises$(): Observable<Enterprise[]> {
    return this.entities$;
  }

  get currentEnterprise$(): Observable<any> {
    return this.collection$.pipe(map((item: any) => item.currentEnterprise));
  }

}
