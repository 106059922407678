import { UserItem } from './../../models/user';
import { GlobalService } from './../../global/app.global.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityCacheDispatcher, EntityActionOptions } from '@ngrx/data';

import { Observable, of, from, forkJoin, throwError } from 'rxjs';
import { map, catchError, tap, timeout } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AuthUser } from '../../models/user';


@Injectable()
export class AuthDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global: GlobalService) {
    super('Auth', http, httpUrlGenerator);
  }

  login(user): Observable<AuthUser> {
    const headers = new HttpHeaders().set('X-hideError', 'true')
    return this.http.post<AuthUser>(this.global.loginUrl, user, {headers})
      .pipe(
        catchError(({ error }) => of(null))
      )
  }

  getUserbyAuth(): Observable<AuthUser> {
    return this.http.post<AuthUser>(this.global.authLoginUrl, {})
      .pipe(
        catchError(({ error }) => of(null))
      )
  }

  serverRunning(): Observable<any> {
    return this.http.get<any>(this.global.baseURL+'/gs')
      .pipe(
        map(item=>(item.status == 'Running')),
        catchError(() =>of(false))
      );
  }

  changePassword(body: any): Observable<any> {
    return this.http.post(this.global.changePassword, body, {observe: 'response'});
  }

  refreshToken(): Observable<any> {
    return this.http.get(this.global.refreshTokenUrl, {observe: 'response'});
  }

  forgotPassword(body: any): Observable<any> {
    return this.http.get(this.global.forgotPassword + body, {observe: 'response'});

  }

  createUserLog(logData:any): Observable<any>{
    return this.http.post<any>(this.global.userLogsUrl,logData);
  }

  getWithQuery(params: string | QueryParams | any): Observable<AuthUser[] | any[] | any> {
    const { data } = params;
    return of(data || []);
  }
}

@Injectable()
export class AuthCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: AuthDataService, private router: Router, private entityCacheDispatcher: EntityCacheDispatcher, private global: GlobalService) {
    super('Auth', elementsFactory);
  }

  setData(additional: any): Observable<any> {
    let queryParams: any = { additional };
    return this.getWithQuery(queryParams);
  }

  load(options?: EntityActionOptions): Observable<any> {
    return this.getUserbyAuth();
  }

  get currentUser$(): Observable<AuthUser> {
    return this.collection$.pipe(map((item: any) => item.currentUser));
  }

  getUserbyAuth(): Observable<AuthUser> {
    let currentUser: any = localStorage.getItem('currentUser');
    currentUser = currentUser ? JSON.parse(currentUser) || null : null;
    return (!currentUser ? of(null) : this.dataService.getUserbyAuth())
    .pipe(
     // timeout(5000),
      catchError(err=> of(null)),
      map(currentUser=> {
        currentUser = currentUser && currentUser.user ? new AuthUser({...currentUser}):null ;
        this.setCurrentUser(currentUser);
        return currentUser;
      }));
  }

  login(userObj): Observable<AuthUser> {
    return this.dataService.login(userObj)
    .pipe(
      catchError(err=> of(null)),
      map(currentUser=> {
        currentUser = currentUser && currentUser.user ? new AuthUser({...currentUser}):null ;
        this.setCurrentUser(currentUser);
        return currentUser;
      }));
  }

  setCurrentUser(currentUser:AuthUser) {
    if(currentUser) {
      let savedUser:any = localStorage.getItem("currentUser");
      savedUser = JSON.parse(savedUser) || {};
      let userIndex = currentUser.userIndex!=null ? currentUser.userIndex : savedUser.userIndex ;
      let profile_photo = ((currentUser.userRoles||[]).find(item=>item.profile_photo!='') ||{}).profile_photo;
      let user = (userIndex!=null) ? (currentUser.userRoles[userIndex]) || currentUser.user : currentUser.defaultUser;
      currentUser = {
        ...savedUser,
        ...currentUser,
        userIndex,
         user : new UserItem({...user, userRoles: currentUser.userRoles}),
         authData: currentUser.user ? window.btoa(currentUser.user.user_name) : null,
         profile_photo
      };
      savedUser = {
        ...savedUser,
        token:currentUser.token,
        userIndex
      }
     // localStorage.setItem('access_token', currentUser.token);
      //localStorage.setItem('user-roles', JSON.stringify(currentUser.userRoles))
      localStorage.setItem("currentUser", JSON.stringify (savedUser));
      this.global.currentUserRoleId = +currentUser.user.role_id;
      this.global.currentEnterpriseId = currentUser.user.enterprise_id;
      this.global.currentUserId = currentUser.user.user_id;
      this.global.currentUserToken = currentUser.token;
      this.global.currentUser = currentUser;
      this.global.currentUserName = currentUser.user.name;
      this.global.dateFormat = currentUser.user.preferences ? currentUser.user.preferences.dateFormat || this.global.dateFormat: this.global.dateFormat;
      /*this.global.userDetails.next({
        user: this.global.currentUser,
        userId: this.global.currentUserId,
        userName: this.global.currentUserName,
        userRoleId: this.global.currentUserRoleId,
        enterpriseId: this.global.currentEnterpriseId
      })*/
        
    } else {
      localStorage.removeItem('access_token');
      localStorage.removeItem("currentUser");
    }
    this.setData({ currentUser});
    this.setLoaded(true);
  }

  serverRunning(): Observable<any> {
    return this.dataService.serverRunning();
  }

  changePassword(body: any): Observable<any> {
    return this.dataService.changePassword(body);
  }

  refreshToken(): Observable<any> {
    return this.dataService.refreshToken();
  }

  forgotPassword(body: any): Observable<any> {
    return this.dataService.forgotPassword(body);
  }

  createUserLog(logData:any): Observable<any>{
    return this.dataService.createUserLog(logData);
  }

  logout() {
    let tutorial = localStorage.getItem('hideWalkthrough');
    let landing = localStorage.getItem('hideLanding');    
    let errorMsg = localStorage.getItem('errorMsg');    
    let cookie_consent = localStorage.getItem('cookie_consent_calculator');
    
    this.entityCacheDispatcher.setEntityCache({}); // Clear Store upon signOut
    localStorage.clear();
    
    if(cookie_consent)
    localStorage.setItem('cookie_consent_calculator', cookie_consent)
    if(tutorial)
    localStorage.setItem('hideWalkthrough', tutorial)
    if(landing)
    localStorage.setItem('hideLanding', landing)
    if(errorMsg)
    localStorage.setItem('errorMsg', errorMsg);

    this.router.navigateByUrl('/login', {replaceUrl: true});
    location.reload();
  }

}
