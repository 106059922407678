import * as _ from 'lodash';
export class Document {
    color?: string;
    created_user_id?: number;
    description?: string;
    sort_order?: string;
    document_id?: number;
    document_title?: string;
    document_path?:string;
    document_type?: string;
    enterprise_id?: number;
    filename?: string;
    last_edit_date?: Date;
    modified_user_id?: number;
    path: string ='/';
    share_all?: string = 'n'
    share_users: any = {users:[],teams:[]};
    upload_date:Date;
    url:string;    
    deleted?:string = 'n';
    size:number = 0;
    document_size:number = 0;
    

    constructor(data) {
        Object.keys(data).forEach(key => {
            if(key.toLowerCase().includes('date')) 
                this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        });
        this.document_title = (this.document_title||'').replace(/%20/g,' ');
        if(!this.document_size)
            this.document_size = 0;
        if(!this.size)
            this.size = 0;
        if(this.document_path)
        this.path = this.document_path || this.path;
    }
   
}
