import { Document } from './../../models/document';
import { DocumentCollectionService } from './document.service';
import { Action } from '../../models/action';
import { EnterpriseService } from '../../services/enterprise.service';
import { GlobalService } from '../../global/app.global.service';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityCacheDispatcher, EntityActionOptions } from '@ngrx/data';

import { Observable, of, throwError, forkJoin, combineLatest } from 'rxjs';
import { map, catchError, switchMap, filter, timeout, take, tap } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable()
export class ActionDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private documentService: DocumentCollectionService, private authService: AuthCollectionService ) {
    super('Action', http, httpUrlGenerator);
  }

  getAll(): Observable<Action[]> {
    return this.getAllActions();
  }

  getWithQuery(params: string | QueryParams | any): Observable<Action[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(action: Action): Observable<Action> {
    let documents: any = (action.documents || []).map(item=>item.document_id);
    documents = JSON.stringify(documents);
    let tasks:any = action.tasks || [];
    tasks = JSON.stringify(tasks);
    //let assigned_to:any = action.assigned_to || [];
    //assigned_to = JSON.stringify(assigned_to);
    return this.http.post<any>(this.global.createActionsUrl, {...action, documents, tasks})
    .pipe(
      map(item=>{
        item = item.data||item;
        let docs = item.documents ? this.mapJSON(item.documents) : [];
        documents= (action.documents || []).filter(item=>docs.includes(item.document_id))
        .map(doc=>new Document(doc));
        return new Action({...action, ...item, documents});
      }),
      catchError((error) => throwError(error))
    );
  }

  update(action: Update<Action>): Observable<any> {
    let {changes} = action;
    let documents: any = (changes.documents || []).map(item=>item.document_id);
    documents = JSON.stringify(documents);
    let tasks:any = changes.tasks || [];
    tasks = JSON.stringify(tasks);
    //let assigned_to:any = changes.assigned_to || [];
    //assigned_to = JSON.stringify(assigned_to);
    let url = this.global.updateActionsUrl.replace('{action_id}', <string> action.id);
    return this.http.put<any>(url, {...changes, documents, tasks})
    .pipe(
      map(item=>{
        item = item.data.data[0]||item.data||item;
        let docs = item.documents ? this.mapJSON(item.documents) : [];
        documents= (changes.documents || []).filter(item=>docs.includes(item.document_id))
        .map(doc=>new Document(doc));
        return new Action({...changes, ...item, documents});
      }),
      catchError((error) => throwError(error))
    );
  }

  delete(key: number | string): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};
        let url = this.global.deleteACtionsUrl;
        url = url.replace('{enterprise_id}', user.enterprise_id);
        url = url.replace('{action_id}', <string>key);
        return (!currentUser && !user) ? of(false) : this.http.delete<any>(url)
      }),
      catchError((error) => throwError(error))
    );
  }

  getAllActions(): Observable<Action[]> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {defaultUser} = currentUser || {}; 
        return (!currentUser && !defaultUser) ? of([]) :
        combineLatest([
          this.documentService.collection$.pipe(filter(coll=>!!coll.loaded)),
          this.getActions(defaultUser.enterprise_id, defaultUser.user_id, '')
        ])
        .pipe(
          map(([collection, result]) => {
            return (result || [] ).map(item=> {
              let documents = item.documents ? this.mapJSON(item.documents) : [];
              documents= documents.map(item=> {
                let id = _.isObject(item) ? +item.document_id : +item;
                return collection.entities[id] ?  new Document(collection.entities[id]) : null;
              }).filter(item=>item!=null);
              return new Action({...item, documents});
            })
          }))
      }),      
      catchError(err => of([]))
    );
  }

  mapJSON(array):any[] {
    let docs = array || [];
    if(_.isString(array)) {
      try {
        docs=JSON.parse(array) || [];
        if(!Array.isArray(docs)) {
          docs = [];
        }
      } catch (error) {
        docs = [];
      }
    }
    return docs;
  }

  getActions(enterpriseId:any, userId:any, categoryId:any): Observable<any> {
    let url = this.global.getActionsUrl;
    url = url.replace('{enterprise_id}', enterpriseId);
    url = url.replace('{user_id}', userId);
    url = url.replace('{category_id}', categoryId);
    return this.http.get<any>(url);
  }

  /*updateActionDate(data:any): Observable<any>{
    // console.log(data);
    let url = this.global.updateActionDateUrl;
    // console.log(url);
    return this.http.post<any>(url,data);
  }*/
}

@Injectable()
export class ActionCollectionService  extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: ActionDataService) {
    super('Action', elementsFactory);
  }

  get actions$(): Observable<Action[]> {
    return this.entities$;
  }
  
  add(entity: Partial<any>, options: EntityActionOptions & { isOptimistic: false; }): Observable<any>;
  add(entity: any, options?: EntityActionOptions): Observable<any>;
  add(entity: unknown, options?: unknown): Observable<any> {
    return super.add(entity).pipe(tap(()=>super.load().subscribe()));
  }

}
