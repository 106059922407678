import { debounceTime, filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GlobalService } from '../global/app.global.service';
import { AppDataServices } from '../store/entity/entity-services';
import { combineLatest } from 'rxjs';



@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private appDataServices: AppDataServices
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return combineLatest([
            this.appDataServices.authService.currentUser$,
            this.appDataServices.loaded$.pipe(filter(loaded=>!!loaded)),
        ]).pipe(
            debounceTime(200),
            map(([currentUser, loaded])=>{
                if(loaded && currentUser && currentUser.token){
                    // if Home page is open only Admin is allowed
                    if((currentUser.roles.includes(1) && state.url.includes('/home')) || !state.url.includes('/home') )
                        return true;
                    else {
                        this.router.navigate(['/']);
                        return false;
                    }
                   
                }
               

                // not logged in so redirect to login
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
                return false;
            })
        )
    }
}