
import * as _ from 'lodash';
export class Goal {
    completed_date: Date;
    created_date:  Date;
    created_user_id: String;
    description: String;
    enterprise_id: String;
    firstname: String;
    goal_category_id: String;
    goal_id: String;
    goal_level_id: String;
    goal_members: String;
    goal_teams: String;
    goal_title: String;
    goal_type_id: String;
    lastname: String;
    milestone_count: number;
    modified_date:  Date;
    modified_user_id: String;
    remarks: String;
    row_number: number;
    scheduled_date:  Date;
    target_date:  Date;
    total_count: number;
    progress?:number = 0;
    milestones?:any = [];
    constructor(data) {
        for (const key in data) {
            if(key.toLowerCase().includes('date'))
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        }
    }
}