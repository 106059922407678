import { Constants } from './../../global/app.global.constants';
import { UserLog } from './../../models/userLog';
import { GlobalService } from './../../global/app.global.service';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { Observable, of, throwError } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';
import { sortComparerName } from '../entity/entity-metadata';
import { AuthUser, UserItem } from '../../models/user';


@Injectable()
export class UserDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private authService: AuthCollectionService ) {
    super('User', http, httpUrlGenerator);
  }

  getAll(): Observable<UserItem[]> {
    return this.loadUsers();
  }

  getWithQuery(params: string | QueryParams | any): Observable<UserItem[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(user: UserItem[]): Observable<UserItem> {
     return this.http.post<any>(this.global.createUserUrl, user)
    .pipe(
      map(res=> {
        let user_item = res.user_id[0] || {user_id:-1};
        return new UserItem({...user[0], ...user_item});
      }),
      catchError((error) => throwError(error)),
    );
  }

  update(user: Update<UserItem>): Observable<any> {
    let url = this.global.updateUserUrl.replace('{user_id}', <string> user.id);
    return this.http.put<any>(url, {...user.changes})
    .pipe(
      map(item=> new UserItem({...user.changes, ...(item.data||item)})),
      catchError((error) => throwError(error))
    );
  }

  delete(key: number | string): Observable<any> {
    let url = this.global.deleteUserUrl.replace('{user_id}', <string>key);
    return this.http.delete<any>(url)
    .pipe(
      catchError((error) => throwError(error))
    );
  }

  loadUsers(): Observable<UserItem[]> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        return (!currentUser && !user) ? of([]) : 
        this.getAllUsers(user.enterprise_id, 2 , 1, 1000, 'firstname', 'asc')
        .pipe(
          map(result => {
            return this.toAuthUsers(result || [] )
            .sort(sortComparerName);
          }))
      }),      
      catchError(() => of([]))
    );
  }

  toAuthUsers(array) {
    let users = (array||[]).reduce((acc,curr)=>{
      if(!curr) return acc;
      curr = new UserItem(curr);
      if(!curr.user_name && curr.email)
        curr.user_name = curr.email;

      acc[curr.user_name] = acc[curr.user_name] ? acc[curr.user_name] : curr;        
      let userRoles  = [...(acc[curr.user_name].userRoles||[]), curr];
      let defaultUser = (userRoles||[]).find(role=>+role.role_id == Constants.roles.INDIVIDUAL) ||
                        (userRoles||[]).find(role=>+role.role_id == Constants.roles.TEAM) || curr;
      acc[curr.user_name] = new UserItem({...defaultUser , userRoles, defaultUser : new UserItem({...defaultUser, userRoles})});
      
      return acc;
    },{});
    return Object.values(users);
  }

  getAllUsers(enterprise_id:any, role_id:any,pageNumber:any, pageSize:any, sortColumn:any, sortOrder:any): Observable<UserItem[]> {
    let url = this.global.getAllUsersUrl;
    url = url.replace('{enterprise_id}', enterprise_id);
    url = url.replace('{role_id}', role_id);
    url = url.replace('{pageNumber}', pageNumber);
    url = url.replace('{pageSize}', pageSize);
    url = url.replace('{sortColumn}', sortColumn);
    url = url.replace('{sortOrder}', sortOrder);

    return this.http.get<UserItem[]>(url);
  }

  getTeamManagers(): Observable<any> {
    let url = this.global.getTeamManagers;
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {};
        if(user) url = url.replace('{enterprise_id}', user.enterprise_id);
        return (!currentUser && !user) ? of([]) : this.http.get<any>(url)
        .pipe(
          map(result => {
            return (result || [] )
            .map(item =>  new UserItem(item))
            .sort(sortComparerName);
          }))
      }),      
      catchError(() => of([]))
    );
    
  }
  

  createUserLog(activity, description): Observable<any>{    
     return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        let userLog:UserLog = {activity, description,
          enterprise_id: user.enterprise_id,
          user_id: user.user_id
        }
        return this.http.post<any>(this.global.userLogsUrl, userLog);
      }),      
      catchError((error) => throwError(error))
    );
  }
}

@Injectable()
export class UserCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: UserDataService) {
    super('User', elementsFactory);
  }

  get users$(): Observable<UserItem[]> {
    return this.entities$;
  }

  createUserLog(activity, description): Observable<any>{
    return this.dataService.createUserLog(activity, description);
  }

  getTeamManagers(): Observable<any>{
    return this.dataService.getTeamManagers();
  }

}
