import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { DatePickerFormatDirective } from './date-picker-format.directive';
import { FocusDirective } from './focus.directive';
import { ResizableDirective } from './resizable.directive';



@NgModule({
  declarations: [
    DatePickerFormatDirective,
    FocusDirective,
    ResizableDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatSelectModule,
    NgApexchartsModule    
  ],
  exports:[DatePickerFormatDirective, FocusDirective, ResizableDirective]
})
export class DirectivesModule { }
