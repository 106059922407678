import { EnterpriseService } from '../../services/enterprise.service';
import { GlobalService } from '../../global/app.global.service';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityCacheDispatcher, EntityActionOptions } from '@ngrx/data';

import { Observable, of, forkJoin } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';


@Injectable()
export class CommonDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private service: EnterpriseService, private authService: AuthCollectionService ) {
    super('Common', http, httpUrlGenerator);
  }

  getWithQuery(params: string | QueryParams | any): Observable<any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  loadAll(): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      take(1),
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        return (!currentUser && !user) ? of([]) : 
        forkJoin([
          this.getGoalLevels(user.enterprise_id),
          this.getGoalTypes(user.enterprise_id),
          this.getPriorities(user.enterprise_id),
          this.getUserRoles(),
        ])
        .pipe(
          map(([goalLevels, goalTypes, priorities, userRoles]) => {
            return {goalLevels, goalTypes, priorities, userRoles};
          }))
      }),      
      catchError(err => of([]))
    );
  }

  getGoalLevels(enterpriseId:any): Observable<any[]> {
    let Url = this.global.getGoalLevelUrl;
    Url = Url.replace('{enterprise_id}', enterpriseId);
    // console.log("Get Levels....");
    // console.log(Url);
    return this.http.get<any[]>(Url)
    .pipe(
      map(array=>array||[]),
      catchError(err => of([]))
    );
  }

  getGoalTypes(enterpriseId:any): Observable<any[]> {
    let Url = this.global.getGoalTypeUrl;
    Url = Url.replace('{enterprise_id}', enterpriseId);
    // console.log(Url);
    return this.http.get<any[]>(Url)
    .pipe(map(array=>array||[]),
    catchError(err => of([])));
  }

  getPriorities(enterpriseId:any): Observable<any[]> {
    let Url = this.global.getActionPrioritiesUrl;
    Url = Url.replace('{enterprise_id}', enterpriseId);
    return this.http.get<any[]>(Url)
    .pipe(map(array=>array||[]),
    catchError(err => of([])));
  }

  getUserRoles(): Observable<any[]> {
    return this.http.get<any[]>(this.global.userRolesUrl)
    .pipe(map(array=>array||[]),
    catchError(err => of([])));
  }
}

@Injectable()
export class CommonCollectionService  extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: CommonDataService) {
    super('Common', elementsFactory);
  }

  load(options?: EntityActionOptions): Observable<any[]> {
    return this.dataService.loadAll().pipe(
      map(data => {
        this.setData(data);
        this.setLoaded(true);
        return data;
      }
    ))
  }

  setData(additional: any): Observable<any> {
    let queryParams: any = { additional };
    return this.getWithQuery(queryParams);
  }
  
  get goalTypes$(): Observable<any[]> {
    return this.collection$.pipe(map((item: any) => item.goalTypes));
  }
  get goalLevels$(): Observable<any[]> {
    return this.collection$.pipe(map((item: any) => item.goalLevels));
  }

  get userRoles$(): Observable<any[]> {
    return this.collection$.pipe(map((item: any) => item.userRoles));
  }

  get priorities$(): Observable<any[]> {
    return this.collection$.pipe(map((item: any) => item.priorities));
  }

}

